import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import * as models from '../../../infrastructure/models/generated';
import {forkJoin, Subject} from 'rxjs';
import {DialogRefService} from '../../../dialog/services/dialog-ref.service';
import {take, takeUntil, tap} from 'rxjs/operators';
import {NgForm} from '@angular/forms';
import {ProjectManager} from '@statera/sdk/project';

@Component({
  selector: 'app-add-project-notes',
  templateUrl: './add-project-notes.component.html',
  styleUrls: ['./add-project-notes.component.scss']
})
export class AddProjectNotesComponent implements OnInit, OnDestroy {
  updateProjectNotes: models.IUpdateProjectNotesViewModel;
  areNewNotes: boolean;
  readonly projectId: number;
  readonly notes?: string;

  private readonly _dialogRefService: DialogRefService;
  private readonly _destroy$: Subject<void>;
  private readonly _projectManager: ProjectManager;

  constructor(
    dialogRefService: DialogRefService,
    projectManager: ProjectManager,
  ) {
    this._dialogRefService = dialogRefService;
    this._projectManager = projectManager;
    this._destroy$ = new Subject<void>();
  }

  ngOnInit(): void {
    this.updateProjectNotes = <models.IUpdateProjectNotesViewModel>{
      projectId: this.projectId,
      notes: this.notes,
    };

    this.areNewNotes = !this.notes;

    this._dialogRefService.onContentReady
      .pipe(
        tap((event) => {
          if (!event || !event.component) {
            return;
          }

          const contentElement = event.component.content();
          if (contentElement) {
            const parent = contentElement.parentElement;
            if (parent) {
              parent.style.setProperty('border-radius', '18px', 'important');
            }
          }
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  submit(form: NgForm): void {
    console.log(form, 'submit');
    if (form.invalid) {
      return;
    }
    this._sendNotes(this.updateProjectNotes.notes);
  }

  deleteNotes(): void {
    console.log('delete notes');
    this._sendNotes(null);
  }

  close(): void {
    this._dialogRefService.hide();
  }

  _sendNotes(notes?: string) {
    this._projectManager
      .updateProjectNotes(this.projectId, notes)
      .pipe(
        tap(() => {
          this._dialogRefService.hide();
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }
}
