import { Injectable } from '@angular/core';
import { FeatureCollection, Point } from 'geojson';
import { BehaviorSubject } from 'rxjs';

import * as models from './building.model';

@Injectable({
  providedIn: 'root',
})
export class BuildingStore {
  readonly building: BehaviorSubject<models.BuildingViewModel>;
  readonly buildingAvailableUnits: BehaviorSubject<Array<models.BuildingUnitViewModel>>;
  readonly buildingSitePlans: BehaviorSubject<Array<models.PlanViewModel>>;

  readonly buildingDemographicsReports: BehaviorSubject<Array<models.DemographicsReport>>;
  readonly buildingDemographicsReportsSelectedReportIndex: BehaviorSubject<number>;
  readonly buildingDemographicsReportsSelectedAreaIndex: BehaviorSubject<number>;
  readonly buildingDemographicsReportsSelectedReportShouldUseLargerScope: BehaviorSubject<boolean>;

  readonly buildingPointsOfInterest: BehaviorSubject<FeatureCollection<Point>>;
  readonly buildingPointsOfInterestSelectedPointIndex: BehaviorSubject<number>;

  readonly buildingCentralBusinessDistricts: BehaviorSubject<FeatureCollection<Point>>;
  readonly buildingCentralBusinessDistrictsSelectedDistrictIndex: BehaviorSubject<number>;
  readonly buildingCentralBusinessDistrictsIsMapLayerEnabled: BehaviorSubject<boolean>;

  constructor() {
    this.building = new BehaviorSubject<models.BuildingViewModel>(null);
    this.buildingAvailableUnits = new BehaviorSubject<Array<models.BuildingUnitViewModel>>(null);
    this.buildingSitePlans = new BehaviorSubject<Array<models.PlanViewModel>>(null);

    this.buildingDemographicsReports = new BehaviorSubject<Array<models.DemographicsReport>>(null);
    this.buildingDemographicsReportsSelectedReportIndex = new BehaviorSubject<number>(0);
    this.buildingDemographicsReportsSelectedAreaIndex = new BehaviorSubject<number>(null);
    this.buildingDemographicsReportsSelectedReportShouldUseLargerScope = new BehaviorSubject<boolean>(false);

    this.buildingPointsOfInterest = new BehaviorSubject<FeatureCollection<Point>>(null);
    this.buildingPointsOfInterestSelectedPointIndex = new BehaviorSubject<number>(null);

    this.buildingCentralBusinessDistricts = new BehaviorSubject<FeatureCollection<Point>>(null);
    this.buildingCentralBusinessDistrictsSelectedDistrictIndex = new BehaviorSubject<number>(null);
    this.buildingCentralBusinessDistrictsIsMapLayerEnabled = new BehaviorSubject<boolean>(false);
  }
}
