import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

import { AlertManager } from '@statera/sdk/alert';
import { Role } from '@statera/sdk/auth';
import { Feature } from '@statera/sdk/feature-toggling';

import { ProjectQuery } from '../../../shared/states/project/project.query';
import { AuthService } from '../../../auth/services/auth.service';

import * as models from '@statera/sdk/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('signOutForm') signOutForm: any;

  get isAuthenticated(): boolean {
    return this._authService.isAuthenticated;
  }

  avatarUrl;
  displayName;
  role: string;
  Role: typeof Role = Role;
  UserStatus: typeof models.UserStatus = models.UserStatus;
  alertCount: number;
  projectsIndicatorClasses: string;

  profileInfo$: Observable<{role: string, isProfileCompleted: boolean, hasAnyLease: boolean}>;

  showProjectSchedultLink = false;

  info: models.IStartupInfoViewModel;

  readonly Feature: typeof Feature = Feature;
  readonly StateraClaimType: typeof models.StateraClaimTypeAsEnum = models.StateraClaimTypeAsEnum;
  readonly StateraClaimValue: typeof models.StateraClaimValueAsEnum = models.StateraClaimValueAsEnum;

  private readonly _authService: AuthService;
  private readonly _router: Router;
  private readonly _projectQuery: ProjectQuery;
  private readonly _alertManager: AlertManager;
  private readonly _destroy$: Subject<void>;

  constructor(
    authService: AuthService,
    router: Router,
    projectQuery: ProjectQuery,
    alertManager: AlertManager,
  ) {
    this._authService = authService;
    this._router = router;
    this._projectQuery = projectQuery;
    this._alertManager = alertManager;
    this._destroy$ = new Subject<void>();
  }

  ngOnInit(): void {
    this.profileInfo$ = this._authService
      .infoLoadComplete
      .pipe(
        map(info => {
          return {
            role: info.role.toLowerCase(),
            isProfileCompleted: !info.emptyProfile && info.userStatus === models.UserStatus.Activated,
            hasAnyLease: info.hasAnyLease,
          };
        }),
      );

    this._authService
      .infoLoadComplete
      .pipe(
        tap(info => {
          this.avatarUrl = this._authService.avatarUrl;
          this.displayName = this._authService.displayName;
          this.role = this._authService.role;

          this.info = info;

          // Prevent subscriptions to API endpoints that cannot be accessed if the user's status is not activated
          if (info.userStatus !== models.UserStatus.Activated) {
            return;
          }

          this._alertManager
            .getLatestRealtimeAlertGroup()
            .pipe(
              tap(alertGroup => {
                if (!alertGroup) {
                  this.alertCount = 0;
                  return;
                }

                this.alertCount = alertGroup.totalUnreadCount;
              }),
              takeUntil(this._destroy$),
            )
            .subscribe();

          this._projectQuery
            .selectLoading()
            .pipe(
              takeUntil(this._destroy$),
            )
            .subscribe(isLoading => {
              if (!isLoading) {
                if (this._projectQuery.hasAllClosed()) {
                  this.projectsIndicatorClasses = 'closed';
                } else {
                  if (this._projectQuery.hasActionsRequired(this.role, this._authService.userId,
                    this._authService.startupInfo.teamAlignmentRoles)) {
                    this.projectsIndicatorClasses = 'error';
                  } else {
                    this.projectsIndicatorClasses = 'success';
                  }
                }

                if (this.projectsIndicatorClasses) {
                  this.projectsIndicatorClasses += ' indicator';
                }

                if (this._projectQuery.hasProjectWithCurrentState()) {
                  this.showProjectSchedultLink = true;
                }
              }
            });
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    this._authService.logoutForm = this.signOutForm.nativeElement;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  signOut() {
    this._authService.logout();
  }

  openChannels() {
    const el = document.querySelector('#channels');
    if (el) {
      el.classList.add('active');
    }
  }

  openInquiriesAndRequests(): void {
    const urlTree = this._router
      .createUrlTree([
        'v2',
        'inquiries-and-requests',
      ]);

    window.location.href = this._router.serializeUrl(urlTree);
  }

  openAvailabilities(): void {
    const urlTree = this._router
      .createUrlTree([
        'v2',
        'available-units',
      ]);

    window.location.href = this._router.serializeUrl(urlTree);
  }

  openOwnCompanyAvailabilities(): void {
    const urlTree = this._router
      .createUrlTree([
        'v2',
        'companies',
        this.info.companyId,
        'available-units',
      ]);

    window.location.href = this._router.serializeUrl(urlTree);
  }

  shouldShowAvailabilitiesLink(): boolean {
    const isLandlord = this.role === Role.Landlord;
    const isUserActivated = this.info.userStatus !== this.UserStatus.Deactivated;
    return this.info && !this.info.isPending && isUserActivated && isLandlord;
  }

  showDocumentLink(): boolean {
    const brokerOrTenant = this.role === Role.Tenant || this.role === Role.Broker;
    return this.info && !this.info.isPending && brokerOrTenant;
  }
}
