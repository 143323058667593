import { Injectable } from '@angular/core';
import { Feature, FeatureCollection, Point } from 'geojson';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { CommonTools } from '@statera/sdk/common';
import { LoggerService, LoggerTopic } from '@statera/sdk/logger';

import * as models from './available-unit.model';

import { AvailableUnitRepository } from './available-unit.repository';

@Injectable()
export class AvailableUnitManager {
  private static readonly _defaultFiltersOrder: Array<models.AvailableUnitFilterType> = [
    models.AvailableUnitFilterType.Company,
    models.AvailableUnitFilterType.Market,
    models.AvailableUnitFilterType.SubMarket,
    models.AvailableUnitFilterType.City,
    models.AvailableUnitFilterType.AssetType,
    models.AvailableUnitFilterType.Class,
    models.AvailableUnitFilterType.AvailableSize,
    models.AvailableUnitFilterType.ClearHeight,
  ];

  private readonly _availableUnitRepository: AvailableUnitRepository;
  private readonly _loggerService: LoggerService;

  constructor(availableUnitRepository: AvailableUnitRepository, loggerService: LoggerService) {
    this._availableUnitRepository = availableUnitRepository;
    this._loggerService = loggerService;
  }

  /**
   * Requests management
   */

  checkAccess(companyId: number): Observable<void> {
    return this._availableUnitRepository
      .checkAccess(companyId)
      .pipe(
        tap(() => {
          this._loggerService.info(LoggerTopic.AvailableUnit, `Access confirmed`);
        }),
      );
  }

  requestBuildingsWithAvailableUnitsList(options: models.AvailableUnitListOptions): Observable<FeatureCollection<Point>> {
    if (!options || !options.boundingBox) {
      return of(null);
    }

    return this._availableUnitRepository
      .requestBuildingsWithAvailableUnitsList(options)
      .pipe(
        tap(buildingsWithAvailableUnits => {
          this._availableUnitRepository.setStoredList(buildingsWithAvailableUnits);

          this._loggerService.info(
            LoggerTopic.AvailableUnit,
            `Buildings with available units received and stored`,
            buildingsWithAvailableUnits,
          );
        }),
        catchError(error => {
          this._availableUnitRepository.setStoredListError(error);

          return throwError(error);
        }),
      );
  }

  requestListFilters(options: models.AvailableUnitListOptions): Observable<models.AvailableUnitListFilters> {
    if (!options || !options.boundingBox) {
      return of(null);
    }

    this.setIsListFiltersLoaded(false);

    return this._availableUnitRepository
      .requestListFilters(options)
      .pipe(
        tap(filters => {
          this._availableUnitRepository.setStoredListFilters(filters);

          this._loggerService.info(LoggerTopic.AvailableUnit, `Filters received and stored`, filters);

          this.setIsListFiltersLoaded(true);
        }),
        catchError(error => {
          this.setIsListFiltersLoaded(true);

          this._availableUnitRepository.setStoredListFiltersError(error);

          return throwError(error);
        }),
      );
  }

  requestBuildingWithAvailableUnit(buildingId: number): Observable<Feature<Point>> {
    if (!buildingId) {
      return of(null);
    }

    return this._availableUnitRepository
      .requestBuildingWithAvailableUnits(buildingId);
  }

  requestAvailableUnit(buildingId: number, unitId: number): Observable<models.BuildingUnitViewModel> {
    return this._availableUnitRepository
      .requestAvailableUnit(buildingId, unitId)
      .pipe(
        catchError(error => {
          this._availableUnitRepository.setStoredSelectedPointSelectedAvailableUnitError(error);

          return throwError(error);
        }),
      );
  }

  requestSearchSuggestions(companyId: number, searchQuery: string): Observable<Array<models.AvailableUnitSearchSuggestion>> {
    return this._availableUnitRepository
      .requestSearchSuggestions(companyId, searchQuery);
  }

  /**
   * Store management
   */

  getBuildingsWithAvailableUnits(): Observable<FeatureCollection<Point>> {
    return this._availableUnitRepository.getStoredList();
  }

  setBuildingsWithAvailableUnits(buildingsWithAvailableUnits: FeatureCollection<Point>) {
    this._availableUnitRepository.setStoredList(buildingsWithAvailableUnits);

    this._loggerService.info(LoggerTopic.AvailableUnit, `Buildings with available units changed`, buildingsWithAvailableUnits);
  }

  getListOptions(): Observable<models.AvailableUnitListOptions> {
    return this._availableUnitRepository.getStoredListOptions();
  }

  mergeListOptions(availableUnitListOptions: models.AvailableUnitListOptions): void {
    this._availableUnitRepository.mergeStoredListOptions(availableUnitListOptions);

    const options = this._availableUnitRepository.getStoredListOptionsSync();
    if (!options) {
      return;
    }

    this._loggerService.info(LoggerTopic.AvailableUnit, `Available unit list options changed`, {...options});
  }

  getListFilters(): Observable<models.AvailableUnitListFilters> {
    return this._availableUnitRepository
      .getStoredListFilters()
      .pipe(
        switchMap(filters => this
          .getListOptions()
          .pipe(
            map(options => {
              if (!filters || !options) {
                return filters;
              }

              if (filters.companies) {
                filters.companies.forEach(x => {
                  x.selected = options?.companies?.includes(x.id);
                });

                if (filters.companies.length && !filters.companies.find(x => x.selected)) {
                  filters.companies[0].selected = true;
                  this.applyCompanyFilters(filters.companies);
                }
              }

              if (options.markets && filters.markets) {
                filters.markets.forEach(x => {
                  x.selected = options.markets.includes(x.id);
                });
              }

              if (options.subMarkets && filters.subMarkets) {
                filters.subMarkets.forEach(x => {
                  x.selected = options.subMarkets.includes(x.id);
                });
              }

              if (options.cities && filters.cities) {
                filters.cities.forEach(x => {
                  x.selected = options.cities.includes(x.name);
                });
              }

              if (options.assetTypes && filters.buildingTypes) {
                for (const buildingType of filters.buildingTypes) {
                  buildingType.assetTypes.forEach(x => {
                    x.selected = options.assetTypes.includes(x.id);
                  });
                }
              }

              if (options.classes && filters.buildingClasses) {
                filters.buildingClasses.forEach(x => {
                  x.selected = options.classes.includes(x.id);
                });
              }

              if (filters.availableSizeRange) {
                filters.availableSizeRange.min = Math.floor(filters.availableSizeRange.min);
                filters.availableSizeRange.max = Math.ceil(filters.availableSizeRange.max);

                filters.availableSizeRange.currentMin = filters.availableSizeRange.min;
                filters.availableSizeRange.currentMax = filters.availableSizeRange.max;
              }

              if (options.availableSize && filters.availableSizeRange) {
                filters.availableSizeRange.currentMin = options.availableSize[0] || filters.availableSizeRange.min;
                filters.availableSizeRange.currentMax = options.availableSize[1] || filters.availableSizeRange.max;
              }

              if (filters.clearHeightRange) {
                filters.clearHeightRange.min = Math.floor(filters.clearHeightRange.min);
                filters.clearHeightRange.max = Math.ceil(filters.clearHeightRange.max);

                filters.clearHeightRange.currentMin = filters.clearHeightRange.min;
                filters.clearHeightRange.currentMax = filters.clearHeightRange.max;
              }

              if (options.clearHeight && filters.clearHeightRange) {
                filters.clearHeightRange.currentMin = options.clearHeight[0] || filters.clearHeightRange.min;
                filters.clearHeightRange.currentMax = options.clearHeight[1] || filters.clearHeightRange.max;
              }

              return filters;
            }),
          ),
        ),
      );
  }

  getFilterBadges(): Observable<Array<models.AvailableUnitFilterBadge>> {
    const options = this._availableUnitRepository.getStoredListOptionsSync();
    if (!options) {
      return of(null);
    }

    return this
      .getListFilters()
      .pipe(
        map(filters => {
          if (!filters) {
            return null;
          }

          const badges = new Array<models.AvailableUnitFilterBadge>();

          let filtersOrder = [...AvailableUnitManager._defaultFiltersOrder];
          if (options.filterApplyingOrder) {
            filtersOrder = [...options.filterApplyingOrder];

            for (const filterType of AvailableUnitManager._defaultFiltersOrder) {
              if (filtersOrder.includes(filterType)) {
                continue;
              }

              filtersOrder.push(filterType);
            }
          }

          for (const filterType of filtersOrder) {
            switch (filterType) {
              case models.AvailableUnitFilterType.Market: {
                if (!filters.markets || !filters.markets.length) {
                  continue;
                }

                filters.markets.forEach(x => {
                  if (!x.selected) {
                    return;
                  }

                  badges.push({
                    name: x.name,
                    remove: () => this.removeMarketFilter(x.id),
                  });
                });

                break;
              }

              case models.AvailableUnitFilterType.SubMarket: {
                if (!filters.subMarkets || !filters.subMarkets.length) {
                  continue;
                }

                filters.subMarkets.forEach(x => {
                  if (!x.selected) {
                    return;
                  }

                  badges.push({
                    name: x.name,
                    remove: () => this.removeSubMarketFilter(x.id),
                  });
                });

                break;
              }

              case models.AvailableUnitFilterType.City: {
                if (!filters.cities || !filters.cities.length) {
                  continue;
                }

                filters.cities.forEach(x => {
                  if (!x.selected) {
                    return;
                  }

                  badges.push({
                    name: x.name,
                    remove: () => this.removeCityFilter(x.name),
                  });
                });

                break;
              }

              case models.AvailableUnitFilterType.AssetType: {
                if (!filters.buildingTypes || !filters.buildingTypes.length) {
                  continue;
                }

                filters.buildingTypes.forEach(x => {
                  if (!x.assetTypes || !x.assetTypes.length) {
                    return;
                  }

                  x.assetTypes.forEach(t => {
                    if (!t.selected) {
                      return;
                    }

                    badges.push({
                      name: t.name,
                      remove: () => this.removeAssetTypeFilter(t.id),
                    });
                  });
                });

                break;
              }

              case models.AvailableUnitFilterType.Class: {
                if (!filters.buildingClasses || !filters.buildingClasses.length) {
                  continue;
                }

                filters.buildingClasses.forEach(x => {
                  if (!x.selected) {
                    return;
                  }

                  badges.push({
                    name: x.name,
                    remove: () => this.removeClassFilter(x.id),
                  });
                });

                break;
              }

              case models.AvailableUnitFilterType.AvailableSize: {
                if (!filters.availableSizeRange) {
                  continue;
                }

                const {min, max, currentMin, currentMax} = filters.availableSizeRange;
                if (currentMin <= min && max <= currentMax) {
                  continue;
                }

                badges.push({
                  name: `${CommonTools.humanizeNumber(currentMin)} SF to ${CommonTools.humanizeNumber(currentMax)} SF`,
                  remove: () => this.removeAvailableSizeFilter(),
                });

                break;
              }

              case models.AvailableUnitFilterType.ClearHeight: {
                if (!filters.clearHeightRange) {
                  continue;
                }

                const {min, max, currentMin, currentMax} = filters.clearHeightRange;
                if (currentMin <= min && max <= currentMax) {
                  continue;
                }

                badges.push({
                  name: `${CommonTools.humanizeNumber(currentMin)} F to ${CommonTools.humanizeNumber(currentMax)} F`,
                  remove: () => this.removeClearHeightFilter(),
                });

                break;
              }
            }
          }

          return badges;
        }),
      );
  }

  getFilterLandlord(): Observable<models.AvailableUnitCompanyFilter> {
    const options = this._availableUnitRepository.getStoredListOptionsSync();
    if (!options) {
      return of(null);
    }

    return this
      .getListFilters()
      .pipe(
        map(filters => {
          if (!filters) {
            return null;
          }

          return filters.companies?.find(x => x.selected);
        }),
      );
  }

  setListOptions(availableUnitListOptions: models.AvailableUnitListOptions): void {
    this._availableUnitRepository.setStoredListOptions(availableUnitListOptions);
  }

  getSidebarVisible(): Observable<boolean> {
    return this._availableUnitRepository.getStoredSidebarVisible();
  }

  setSidebarVisible(visible: boolean): void {
    this._availableUnitRepository.setStoredSidebarVisible(visible);
  }

  getIsListFiltersVisible(): Observable<boolean> {
    return this._availableUnitRepository.getIsListFiltersVisible();
  }

  setIsListFiltersVisible(isFiltersVisible: boolean): void {
    this._availableUnitRepository.setIsListFiltersVisible(isFiltersVisible);

    this._loggerService.info(LoggerTopic.AvailableUnit, `Filters visibility changed`, {isFiltersVisible});
  }

  getIsListFiltersLoaded(): Observable<boolean> {
    return this._availableUnitRepository.getIsListFiltersLoaded();
  }

  setIsListFiltersLoaded(isLoaded: boolean): void {
    this._availableUnitRepository.setIsListFiltersLoaded(isLoaded);

    this._loggerService.info(LoggerTopic.AvailableUnit, `Filters loading state changed`, {isLoaded});
  }

  getFocusedPoint(): Observable<Feature<Point>> {
    return this._availableUnitRepository.getStoredFocusedPoint();
  }

  setFocusedPoint(point: Feature<Point>): void {
    this._availableUnitRepository.setStoredFocusedPoint(point);

    this._loggerService.info(LoggerTopic.AvailableUnit, `Focused point changed`, point);
  }

  getSelectedPoint(): Observable<Feature<Point>> {
    return this._availableUnitRepository.getStoredSelectedPoint();
  }

  setSelectedPoint(point: Feature<Point>): void {
    this._availableUnitRepository.setStoredSelectedPoint(point);

    this._loggerService.info(LoggerTopic.AvailableUnit, `Selected point changed`, point);
  }

  getSelectedPointSelectedAvailableUnit(): Observable<models.BuildingUnitViewModel> {
    return this._availableUnitRepository.getStoredSelectedPointSelectedAvailableUnit();
  }

  setSelectedPointSelectedAvailableUnit(availableUnit: models.BuildingUnitViewModel): void {
    this._availableUnitRepository.setStoredSelectedPointSelectedAvailableUnit(availableUnit);

    this._loggerService.info(LoggerTopic.AvailableUnit, `Selected point selected available unit changed`, availableUnit);
  }

  /**
   * Filter management
   */

  applyCompanyFilters(companyFilters: Array<models.AvailableUnitCompanyFilter>): void {
    if (!companyFilters) {
      return;
    }

    const selectedCompanies = new Array<number>();
    for (const company of companyFilters) {
      if (!company.selected) {
        continue;
      }

      selectedCompanies.push(company.id);
    }

    const options = this._updateFilterApplyingOrder(models.AvailableUnitFilterType.Company, !selectedCompanies.length);

    this.mergeListOptions({...options, companies: selectedCompanies});
  }

  applyMarketFilters(marketFilters: Array<models.AvailableUnitMarketFilter>): void {
    if (!marketFilters) {
      return;
    }

    const selectedMarkets = new Array<number>();
    for (const market of marketFilters) {
      if (!market.selected) {
        continue;
      }

      selectedMarkets.push(market.id);
    }

    const options = this._updateFilterApplyingOrder(models.AvailableUnitFilterType.Market, !selectedMarkets.length);

    this.mergeListOptions({...options, markets: selectedMarkets});
  }

  applySubMarketFilters(subMarketFilters: Array<models.AvailableUnitSubMarketFilter>): void {
    if (!subMarketFilters) {
      return;
    }

    const selectedSubMarkets = new Array<number>();
    for (const subMarket of subMarketFilters) {
      if (!subMarket.selected) {
        continue;
      }

      selectedSubMarkets.push(subMarket.id);
    }

    const options = this._updateFilterApplyingOrder(models.AvailableUnitFilterType.SubMarket, !selectedSubMarkets.length);

    this.mergeListOptions({...options, subMarkets: selectedSubMarkets});
  }

  removeMarketFilter(marketId: number): void {
    const options = this._availableUnitRepository.getStoredListOptionsSync();
    if (!options || !options.markets) {
      return;
    }

    const indexOfMarketId = options.markets.findIndex(x => x === marketId);
    if (indexOfMarketId < 0) {
      return;
    }

    options.markets.splice(indexOfMarketId, 1);

    this.mergeListOptions(
      this._updateFilterApplyingOrderByOptions(options, models.AvailableUnitFilterType.Market, !options.markets.length)
    );
  }

  removeSubMarketFilter(subMarketId: number): void {
    const options = this._availableUnitRepository.getStoredListOptionsSync();
    if (!options || !options.subMarkets) {
      return;
    }

    const indexOfSubMarketId = options.subMarkets.findIndex(x => x === subMarketId);
    if (indexOfSubMarketId < 0) {
      return;
    }

    options.subMarkets.splice(indexOfSubMarketId, 1);

    this.mergeListOptions(
      this._updateFilterApplyingOrderByOptions(options, models.AvailableUnitFilterType.SubMarket, !options.subMarkets.length)
    );
  }

  applyCityFilters(cityFilters: Array<models.AvailableUnitCityFilter>): void {
    if (!cityFilters) {
      return;
    }

    const selectedCities = new Array<string>();
    for (const city of cityFilters) {
      if (!city.selected) {
        continue;
      }

      selectedCities.push(city.name);
    }

    const options = this._updateFilterApplyingOrder(models.AvailableUnitFilterType.City, !selectedCities.length);

    this.mergeListOptions({...options, cities: selectedCities});
  }

  removeCityFilter(cityName: string): void {
    const options = this._availableUnitRepository.getStoredListOptionsSync();
    if (!options || !options.cities) {
      return;
    }

    const indexOfCityName = options.cities.findIndex(x => x === cityName);
    if (indexOfCityName < 0) {
      return;
    }

    options.cities.splice(indexOfCityName, 1);

    this.mergeListOptions(
      this._updateFilterApplyingOrderByOptions(options, models.AvailableUnitFilterType.City, !options.cities.length)
    );
  }

  applyAssetTypeFilters(buildingTypeFilters: Array<models.AvailableUnitBuildingTypeFilter>): void {
    if (!buildingTypeFilters) {
      return;
    }

    const selectedAssetTypes = new Array<number>();
    for (const buildingType of buildingTypeFilters) {
      if (!buildingType.assetTypes) {
        continue;
      }

      for (const assetType of buildingType.assetTypes) {
        if (!assetType.selected) {
          continue;
        }

        selectedAssetTypes.push(assetType.id);
      }
    }

    const options = this._updateFilterApplyingOrder(models.AvailableUnitFilterType.AssetType, !selectedAssetTypes.length);

    this.mergeListOptions({...options, assetTypes: selectedAssetTypes});
  }

  removeAssetTypeFilter(assetTypeId: number): void {
    const options = this._availableUnitRepository.getStoredListOptionsSync();
    if (!options || !options.assetTypes) {
      return;
    }

    const indexOfAssetTypeId = options.assetTypes.findIndex(x => x === assetTypeId);
    if (indexOfAssetTypeId < 0) {
      return;
    }

    options.assetTypes.splice(indexOfAssetTypeId, 1);

    this.mergeListOptions(
      this._updateFilterApplyingOrderByOptions(options, models.AvailableUnitFilterType.AssetType, !options.assetTypes.length)
    );
  }

  applyClassFilters(classFilters: Array<models.AvailableUnitBuildingClassFilter>): void {
    if (!classFilters) {
      return;
    }

    const selectedBuildingClasses = new Array<number>();
    for (const buildingClass of classFilters) {
      if (!buildingClass.selected) {
        continue;
      }

      selectedBuildingClasses.push(buildingClass.id);
    }

    const options = this._updateFilterApplyingOrder(models.AvailableUnitFilterType.Class, !selectedBuildingClasses.length);

    this.mergeListOptions({...options, classes: selectedBuildingClasses});
  }

  removeClassFilter(classId: number): void {
    const options = this._availableUnitRepository.getStoredListOptionsSync();
    if (!options || !options.classes) {
      return;
    }

    const indexOfClassId = options.classes.findIndex(x => x === classId);
    if (indexOfClassId < 0) {
      return;
    }

    options.classes.splice(indexOfClassId, 1);

    this.mergeListOptions(
      this._updateFilterApplyingOrderByOptions(options, models.AvailableUnitFilterType.Class, !options.classes.length)
    );
  }

  applyAvailableSizeFilter(availableSizeFilter: models.AvailableUnitAvailableSizeFilter): void {
    if (!availableSizeFilter) {
      return;
    }

    const {min, max} = availableSizeFilter;
    let {currentMin, currentMax} = availableSizeFilter;

    currentMin = Math.floor(currentMin);
    currentMax = Math.ceil(currentMax);

    const isEmpty = (
      (isNaN(min) || !isFinite(min)) ||
      (isNaN(max) || !isFinite(max)) ||
      (min === 0 && max === 0) ||
      (min === currentMin && max === currentMax)
    );

    const options = this._updateFilterApplyingOrder(models.AvailableUnitFilterType.AvailableSize, isEmpty);

    if (!isEmpty) {
      this.mergeListOptions({...options, availableSize: [currentMin, currentMax]});
      return;
    }

    this.mergeListOptions({...options, availableSize: null});
  }

  removeAvailableSizeFilter(): void {
    const options = this._availableUnitRepository.getStoredListOptionsSync();
    if (!options || !options.availableSize) {
      return;
    }

    options.availableSize = null;

    this.mergeListOptions(
      this._updateFilterApplyingOrderByOptions(options, models.AvailableUnitFilterType.AvailableSize, true)
    );
  }

  applyClearHeightFilter(clearHeightFilter: models.AvailableUnitClearHeightFilter): void {
    if (!clearHeightFilter) {
      return;
    }

    const {min, max} = clearHeightFilter;
    let {currentMin, currentMax} = clearHeightFilter;

    currentMin = Math.floor(currentMin);
    currentMax = Math.ceil(currentMax);

    const isEmpty = (
      (isNaN(min) || !isFinite(min)) ||
      (isNaN(max) || !isFinite(max)) ||
      (min === 0 && max === 0) ||
      (min === currentMin && max === currentMax)
    );

    const options = this._updateFilterApplyingOrder(models.AvailableUnitFilterType.ClearHeight, isEmpty);

    if (!isEmpty) {
      this.mergeListOptions({...options, clearHeight: [currentMin, currentMax]});
      return;
    }

    this.mergeListOptions({...options, clearHeight: null});
  }

  removeClearHeightFilter(): void {
    const options = this._availableUnitRepository.getStoredListOptionsSync();
    if (!options || !options.clearHeight) {
      return;
    }

    options.clearHeight = null;

    this.mergeListOptions(
      this._updateFilterApplyingOrderByOptions(options, models.AvailableUnitFilterType.ClearHeight, true)
    );
  }

  removeAllFilters(): void {
    const options = this._availableUnitRepository.getStoredListOptionsSync();
    if (!options) {
      return;
    }

    this.mergeListOptions({
      companies: null,
      markets: null,
      subMarkets: null,
      cities: null,
      assetTypes: null,
      classes: null,
      availableSize: null,
      clearHeight: null,
      filterApplyingOrder: [],
    });
  }

  private _updateFilterApplyingOrder(filterType: models.AvailableUnitFilterType, isEmpty: boolean): models.AvailableUnitListOptions {
    const options = this._availableUnitRepository.getStoredListOptionsSync();
    if (!options) {
      return null;
    }

    return this._updateFilterApplyingOrderByOptions(options, filterType, isEmpty);
  }

  private _updateFilterApplyingOrderByOptions(
    options: models.AvailableUnitListOptions,
    filterType: models.AvailableUnitFilterType,
    isEmpty: boolean
  ): models.AvailableUnitListOptions {
    if (!isEmpty) {
      options.filterApplyingOrder = CommonTools.getUniqueArrayValues([
        ...(options.filterApplyingOrder || []),
        filterType
      ]);
    }

    if (options.filterApplyingOrder && isEmpty) {
      const indexOfFilterType = options.filterApplyingOrder.indexOf(filterType);
      if (0 <= indexOfFilterType) {
        options.filterApplyingOrder.splice(indexOfFilterType, 1);
      }
    }

    return options;
  }
}
