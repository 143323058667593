import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import * as dx from 'devextreme-angular';

import { AccessModule } from '../access/access.module';

import { PlanViewerModule } from '../plan-viewer/plan-viewer.module';
import { InfrastructureModule } from '../infrastructure/infrastructure.module';
import { SharedModule } from '../shared/shared.module';
import { FeatureTogglingModule } from '../feature-toggling/feature-toggling.module';

import { FloorPlanComponent } from './components/floor-plan/floor-plan.component';
import { SitePlanComponent } from './components/site-plan/site-plan.component';
import { InlineSVGModule } from 'ng-inline-svg';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AccessModule,
    PlanViewerModule,
    InfrastructureModule,
    SharedModule,
    FeatureTogglingModule,
    dx.DxButtonModule,
    dx.DxListModule,
    dx.DxNumberBoxModule,
    dx.DxScrollViewModule,
    dx.DxSelectBoxModule,
    dx.DxTabsModule,
    dx.DxTextBoxModule,
    dx.DxTooltipModule,
    dx.DxValidatorModule,
    dx.DxValidationGroupModule,
    InlineSVGModule,
  ],
  declarations: [
    FloorPlanComponent,
    SitePlanComponent,
  ],
  exports: [
    FloorPlanComponent,
    SitePlanComponent,
  ],
  entryComponents: [
    FloorPlanComponent,
    SitePlanComponent,
  ],
})
export class PlanModule {
  static forRoot(): ModuleWithProviders<PlanModule> {
    return {
      ngModule: PlanModule,
    };
  }
}
