import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Role } from '@statera/sdk/auth';
import { DxPopoverComponent } from 'devextreme-angular';
import { forkJoin, Observable, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import * as moment from 'moment';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { BuildingManager } from '@statera/sdk/building';
import { LandlordManager, LandlordStore, DocumentAttachmentPlaces } from '@statera/sdk/landlord';
import { LeaseManager } from '@statera/sdk/lease';
import { TermManager } from '@statera/sdk/term';
import { Feature } from '@statera/sdk/feature-toggling';

import { AuthService } from '../../../auth/services/auth.service';
import { AlertService } from '../../../alert/services/alert.service';
import { DialogService } from '../../../dialog/services/dialog.service';
import { InviteBrokerByLandlordDialogComponent } from '../../../invitation/components/invite-broker-by-landlord-dialog/invite-broker-by-landlord-dialog.component';
import { BrokerInvitationType } from '../../../invitation/models/broker-invitation-type';
import { LeaseService } from '../../../shared/services/lease.service';
import { ListSpaceDialogComponent } from '../list-space-dialog/list-space-dialog.component';
import { ManageTeamDialogComponent } from '../../../team/components/manage-team-dialog/manage-team-dialog.component';

import { ProjectQuery } from '../../../shared/states/project/project.query';

import * as models from '../../../infrastructure/models/generated';
import { AvatarListItem } from '../../../shared/components/avatar-list/avatar-list.component';
import {AddProjectNotesComponent} from '../add-project-notes/add-project-notes.component';

enum SuiteStatus {
  Vacant = 'Vacant',
  InLease = 'In Lease',
  Renewal = 'Renewal',
  Restructure = 'Restructure',
  NewDeal = 'New Deal'
}

@Component({
  selector: 'app-building-desktop',
  templateUrl: './building-desktop.component.html',
  styleUrls: ['./building-desktop.component.scss'],
})
export class BuildingDesktopComponent implements OnInit, OnDestroy {
  documentAttachmentPlaces = DocumentAttachmentPlaces;
  tabs = [{text: 'Deal(s)'}, {text: 'Files'}, {text: 'Units'}];
  selectedTabIndex = 0;
  currentTab = '';
  dealsGridVisible = true;
  attachmentsVisible = false;
  tenantsVisible = false;
  budgetVisible = false;
  id = 0;
  building: models.IBuildingViewModel;
  deals: Array<models.IDealViewModel>;
  suites: Array<models.ISuiteViewModel>;
  tooltipVisible = false;
  year: number;
  projects: Array<models.IProjectViewModel>;
  tabTooltipVisible: Array<boolean> = [];
  SuiteStatus: typeof SuiteStatus;
  buildingPictures?: Array<models.IBuildingAttachmentViewModel>;
  buildingUnits: Array<models.IBuildingUnitViewModel>;
  maxStageCount = 5;

  info: models.IStartupInfoViewModel;

  userRole: Observable<Role>;

  readonly Role: typeof Role = Role;

  readonly StateraClaimType: typeof models.StateraClaimTypeAsEnum = models.StateraClaimTypeAsEnum;
  readonly StateraClaimValue: typeof models.StateraClaimValueAsEnum = models.StateraClaimValueAsEnum;
  readonly Feature: typeof Feature = Feature;

  private readonly _route: ActivatedRoute;
  private readonly _buildingManager: BuildingManager;
  private readonly _landlordStore: LandlordStore;
  private readonly _landlordManager: LandlordManager;
  private readonly _projectQuery: ProjectQuery;
  private readonly _authService: AuthService;
  private readonly _termManager: TermManager;
  private readonly _destroy$: Subject<void>;
  private readonly _alertService: AlertService;
  private readonly _alertMessagesManager: AlertMessagesManager;
  private readonly _leaseService: LeaseService;
  private readonly _dialogService: DialogService;
  private readonly _router: Router;
  private readonly _leaseManager: LeaseManager;
  searchText = '';

  constructor(
    route: ActivatedRoute,
    buildingManager: BuildingManager,
    landlordStore: LandlordStore,
    landlordManager: LandlordManager,
    projectQuery: ProjectQuery,
    authService: AuthService,
    termManager: TermManager,
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    leaseService: LeaseService,
    router: Router,
    dialogService: DialogService,
    leaseManager: LeaseManager,
  ) {
    this._buildingManager = buildingManager;
    this._landlordStore = landlordStore;
    this._landlordManager = landlordManager;
    this._route = route;
    this._projectQuery = projectQuery;
    this._authService = authService;
    this._termManager = termManager;
    this._destroy$ = new Subject<void>();
    this._alertService = alertService;
    this._alertMessagesManager = alertMessagesManager;
    this._leaseService = leaseService;
    this._router = router;
    this._dialogService = dialogService;
    this._leaseManager = leaseManager;

    this.SuiteStatus = SuiteStatus;
    this.getCurrentTenantSquareFootage = this.getCurrentTenantSquareFootage.bind(this);
    this.getCurrentRentalRate = this.getCurrentRentalRate.bind(this);
    this.getCurrentTenantSquareFootageNumber = this.getCurrentTenantSquareFootageNumber.bind(this);
    this.getCurrentRentalRateNumber = this.getCurrentRentalRateNumber.bind(this);
  }

  ngOnInit(): void {
    this.deals = [];
    this.suites = [];
    this.buildingUnits = [];

    this.userRole = this._authService
      .infoLoadComplete
      .pipe(
        map(x => Role[Role[x.role]])
      );

    this._authService
      .infoLoadComplete
      .pipe(
        tap(info => {
          this.info = info;

          if (this.info && this.info.role === Role.Landlord) {
            this.tabs.push({text: 'Budget'});
          }
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();

    this.building = this._buildingManager.findInPortfolios(null, null);
    this._landlordStore
      .portfolios$
      .subscribe(portfolios => {
        this.building = this._buildingManager
          .findInPortfolios(this.id, portfolios);
        if (this.building.attachments?.length > 0) {
          this.buildingPictures = this.getBuildingAttachments();
        }
        this.year = this.building.builtYear;
      });

    this._projectQuery.selectAll().subscribe(projects => {
      this.projects = projects;
    });

    this._route.queryParams.subscribe(params => {
      const tab = params['tab'];
      if (tab === 'units') {
        this.selectedTabIndex = 2;
        this.tabTooltipVisible[this.selectedTabIndex] = !this.tabTooltipVisible[this.selectedTabIndex];
        this.searchText = params['searchText'];
      }
      this.selectTab();
    });

    this._route
      .params
      .pipe(
        map((params: Params) => {
          const buildingId = parseInt(params['id'], 10);

          this.id = buildingId;

          this._landlordStore
            .portfolios$
            .pipe(
              take(1)
            )
            .subscribe(portfolios => {
              this.building = this._buildingManager
                .findInPortfolios(this.id, portfolios);
              if (this.building.attachments?.length > 0) {
                this.buildingPictures = this.getBuildingAttachments();
              }
              this.year = this.building.builtYear;
            });

          return buildingId;
        }),
        switchMap((buildingId: number) => {
          return forkJoin(
            [
              this._landlordManager
                .getDeals(buildingId)
                .pipe(
                  takeUntil(this._destroy$),
                ),
              this._landlordManager
                .getSuites(buildingId)
                .pipe(
                  takeUntil(this._destroy$),
                )
            ]
          )
            .pipe(
              tap(([deals, suites]) => {
                this.deals = deals;
                this.setSuites(suites);
              }),
            );
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  getBuildingAttachments(): Array<models.IBuildingAttachmentViewModel> {
    if (!this.building?.attachments?.length) {
      return [];
    }

    return this.building.attachments;
  }

  toggleTooltip(e) {
    this.tabTooltipVisible[e.itemIndex] = !this.tabTooltipVisible[e.itemIndex];
  }

  selectTab() {
    console.log('selectedTabIndex', this.selectedTabIndex);
    this.currentTab = this.tabs[this.selectedTabIndex].text;
    this.dealsGridVisible = this.currentTab === 'Deal(s)';
    this.attachmentsVisible = this.currentTab === 'Files';
    this.tenantsVisible = this.currentTab === 'Units';
    this.budgetVisible = this.currentTab === 'Budget';
  }

  getBuildingTypeName(buildingType: models.BuildingType): string {
    switch (buildingType) {
      case models.BuildingType.Industrial:
        return 'Industrial';
      case models.BuildingType.Land:
        return 'Land';
      case models.BuildingType.Office:
        return 'Office';
      case models.BuildingType.Retail:
        return 'Retail';
      default:
        return 'Industrial';
    }
  }

  getStatusName(data): string {
    if (!data) {
      return null;
    }

    if (data.parentProjectState) {
      return data.parentProjectState.name;
    }

    if (data.projectState) {
      return data.projectState.name;
    }

    return null;
  }

  getStatusNumber(data): number {
    if (!data) {
      return null;
    }

    if (data.parentProjectState) {
      return data.parentProjectState.stageNumber;
    }

    if (data.projectState) {
      return data.projectState.stageNumber;
    }

    return null;
  }

  getCurrentTenantSquareFootage(deal: models.IDealViewModel): string {
    if (!deal) {
      return 'N/A';
    }

    const value = this._termManager.getCurrentTenantSquareFootage(deal.tenantSquareFootageTerm);
    if (value.indexOf('\n') !== -1) {
      return value.replace('\n', '<br/>');
    }

    return value;
  }

  getCurrentTenantSquareFootageNumber(deal: models.IDealViewModel): number {
    if (!deal) {
      return 0;
    }

    return this._termManager.getCurrentTenantSquareFootageNumber(deal.tenantSquareFootageTerm);
  }

  getCurrentRentalRate(deal: models.IDealViewModel): string {
    if (!deal) {
      return '$0';
    }

    const value = this._termManager.getCurrentRentalRate(deal.baseRentalRateTerm);
    if (value.indexOf('\n') !== -1) {
      return value.replace('\n', '<br/>');
    }

    return value;
  }

  getCurrentRentalRateNumber(deal: models.IDealViewModel): number {
    if (!deal) {
      return 0;
    }

    return this._termManager.getCurrentRentalRateNumber(deal.baseRentalRateTerm);
  }

  handleBuildingAssignLeasingTeamButtonClick(building: models.IBuildingViewModel): void {
    this.openManageTeamDialog({ buildings: [building] });
  }

  handleBuildingManageLeasingTeamButtonClick(building: models.IBuildingViewModel): void {
    this.openManageTeamDialog({ buildings: [building] });
  }

  openManageTeamDialog(
    data: { buildings?: Array<models.IBuildingViewModel> },
  ): void {
    const dialogRef = this._dialogService
      .show(ManageTeamDialogComponent, {
        title: null,
        width: 1024,
        maxHeight: '90vh',
        injectableData: {
          ...data,
          role: this._authService.role,
        },
      });

    dialogRef
      .onHiding
      .pipe(
        switchMap(() => (
          this._landlordManager.getPortfolios()
        )),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  showPopover(popoverComponent: DxPopoverComponent): void {
    if (!popoverComponent || !popoverComponent.instance) {
      return;
    }

    const popoverInstance = popoverComponent.instance;
    popoverInstance.option('closeOnTargetScroll', () => {
      popoverInstance.repaint();
      return true;
    });

    popoverComponent.visible = true;
  }

  hidePopover(popoverComponent: DxPopoverComponent): void {
    if (!popoverComponent || !popoverComponent.instance) {
      return;
    }

    popoverComponent.visible = false;
  }

  isInLease(info: { data: models.ISuiteViewModel, rowElement: Element }): void {
    if (!info || !info.data) {
      return;
    }

    if (!info.data.lease) {
      info.rowElement.classList.add('no-lease');
    }
  }

  getSuiteStatus(suite: models.ISuiteViewModel): SuiteStatus {
    const today = moment().startOf('day');
    const leaseEndDate = moment(suite.leaseEndDate).startOf('day');

    if (!suite || !suite.lease || !suite.leaseEndDate || today.isAfter(leaseEndDate)) {
      return SuiteStatus.Vacant;
    }

    if (suite.lease.leaseStatus === models.LeaseStatus.Expired) {
      return SuiteStatus.Vacant;
    }

    if (suite.project && typeof suite.project.projectStatus === 'number') {
      if (suite.project.projectStatus === models.ProjectStatus.Completed) {
        return SuiteStatus.InLease;
      }

      if (suite.project.projectStatus === models.ProjectStatus.Active) {
        if (!suite.project.projectType) {
          return SuiteStatus.Vacant;
        }

        switch (suite.project.projectType.projectTypeEnum) {
          case models.ProjectTypeEnum.Renewal:
          case models.ProjectTypeEnum.RenewalInitiatedByLandlord:
            return SuiteStatus.Renewal;

          case models.ProjectTypeEnum.Restructure:
            return SuiteStatus.Restructure;

          case models.ProjectTypeEnum.NewDeal:
            return SuiteStatus.NewDeal;

          default:
            return SuiteStatus.Vacant;
        }
      }
    }

    return SuiteStatus.InLease;
  }

  log(object) {
    console.log(object);
  }

  getBriefDealSummaries(leaseId: number): Observable<Array<models.IBriefDealSummaryViewModel>> {
    return this._landlordManager.getBriefDealSummaries(leaseId);
  }

  initiateDeal(button: HTMLButtonElement, suite: models.ISuiteViewModel) {
    button.disabled = true;

    if (this.canInitiateDeal(suite)) {
      let tenantCompanyName = 'Tenant Company';
      if (suite?.tenantCompany?.name) {
        tenantCompanyName = suite.tenantCompany.name;
      }

      const alertReference = this._alertService.pushConfirmAlert({
        message: this._alertMessagesManager.getConfirmLandlordInitiatedDealAlertText(tenantCompanyName),
      });

      alertReference
        .confirmed
        .pipe(
          switchMap(() => this._leaseService
            .renewLeaseByLandlord(suite.lease)
            .pipe(
              takeUntil(this._destroy$),
            )
          ),
          take(1),
          takeUntil(this._destroy$),
        )
        .subscribe(lease => this._showInviteBrokerDialog(lease));

      alertReference
        .declined
        .pipe(
          tap(() => button.disabled = false),
          take(1),
          takeUntil(this._destroy$),
        )
        .subscribe();

      button.disabled = false;

      return;
    }

    button.disabled = false;

    this._alertService.pushWarningAlert({
      message: this._alertMessagesManager.getTooEarlyToInitiateDealAlertText(),
    });
  }

  canInitiateDeal(suite: models.ISuiteViewModel): boolean {
    if (
      suite?.lease?.leaseStatus === models.LeaseStatus.Active &&
      !this._leaseManager.isLeaseExpired(suite.lease)
    ) {
      return this._leaseManager.hasRenewalPeriodBegun(suite.lease, this.info);
    }

    return false;
  }

  getInitiateDealTooltip(suite: models.ISuiteViewModel) {
    if (!suite) {
      return 'Feature coming soon';
    }

    return this._leaseManager
      .getInitialDealTooltip(suite.lease, suite.project, this.info);
  }

  getUnitMetricsDisplayValue(suite: models.ISuiteViewModel): string {
    let unitMetrics = 'PSF/Yr';
    if (!suite || !suite.unitMetrics) {
      return unitMetrics;
    }

    if (suite.unitMetrics === models.BaseRentalRateUnitMetrics.PsfPerMonth) {
      unitMetrics = 'PSF/Mo';
    }

    return unitMetrics;
  }

  goToProjectSchedule(item): void {
    const { lease } = item.data;

    const urlTree = this._router
      .createUrlTree([
        'landlord',
        'buildings',
        'person-desktop',
        lease.id
      ], {queryParams: {target: 'timeline'}});

    const url = this._router.serializeUrl(urlTree);
    window.open(url, '_blank');
  }

  goToLeaseAnalysis(item): void {
    const { lease } = item.data;

    const urlTree = this._router
      .createUrlTree([
        'v2',
        'leases',
        lease.id,
        'lease-analysis',
      ]);
    const url = this._router.serializeUrl(urlTree);
    window.open(url, '_blank');
  }

  manageTeam(event, item) {
    const { lease } = item.data;
    const dialogRef = this._dialogService.show(ManageTeamDialogComponent, {
      title: null,
      width: 1024,
      maxHeight: '90vh',
      injectableData: {
        lease: lease,
        role: this._authService.role,
      },
    });

    if (dialogRef) {
      const dialogHiding$ = new Subject();

      dialogHiding$
        .pipe(
          switchMap(() => {
            return forkJoin(
              [
                this._landlordManager.getDeals(lease.buildingId),
                this._landlordManager.getSuites(lease.buildingId)
              ]
            );
          }),
          tap(([deals, suites]) => {
            this.deals = deals;
            this.setSuites(suites);
          }),
          take(1),
          takeUntil(this._destroy$),
        )
        .subscribe();

      dialogRef
        .onHiding
        .pipe(
          tap(() => dialogHiding$.next()),
          takeUntil(this._destroy$),
        )
        .subscribe();
    }
  }

  addProjectNotes(event, item) {
    const { projectNotes, negotiationProject, lease } = item.data;
    const dialogRef = this._dialogService.show(AddProjectNotesComponent, {
      title: null,
      width: 602,
      height: '420px',
      containerClassNames: ['add-project-notes-container'],
      injectableData: {
        projectId: negotiationProject.id,
        notes: projectNotes
      },
    });

    if (dialogRef) {
      const dialogHiding$ = new Subject();

      dialogHiding$
        .pipe(
          switchMap(() => {
            return forkJoin(
              [
                this._landlordManager.getDeals(lease.buildingId),
                this._landlordManager.getSuites(lease.buildingId)
              ]
            );
          }),
          tap(([deals, suites]) => {
            this.deals = deals;
            this.setSuites(suites);
          }),
          take(1),
          takeUntil(this._destroy$),
        )
        .subscribe();

      dialogRef
        .onHiding
        .pipe(
          tap(() => dialogHiding$.next()),
          takeUntil(this._destroy$),
        )
        .subscribe();
    }
  }

  listSpace(button: HTMLButtonElement, suite: models.ISuiteViewModel): void {
    button.disabled = true;

    this._dialogService
      .show(ListSpaceDialogComponent, {
        title: 'List Space',
        showCloseButton: true,
        closeOnOutsideClick: false,
        width: 580,
        maxHeight: '90%',
        containerClassNames: ['list-space-dialog'],
        injectableData: {
          suite: suite,
          suites: this.suites,
        },
      })
      .onHiding
      .pipe(
        switchMap(() => (
          this._landlordManager
            .getSuites(suite.buildingUnit.buildingId)
        )),
        takeUntil(this._destroy$),
      )
      .subscribe((suites) => {
        this.setSuites(suites);
        button.disabled = false;
      });
  }

  viewListing(suite: models.ISuiteViewModel): void {
    const urlTree = this._router
      .createUrlTree([
        'v2',
        'companies',
        this.info.companyId,
        'available-units',
        'buildings',
        suite.buildingUnit.buildingId,
        'units',
        suite.buildingUnit.id
      ]);

    const url = this._router.serializeUrl(urlTree);

    window.open(url, '_blank');
  }

  private _showInviteBrokerDialog(lease: models.ILeaseViewModel) {
    const brokerInvited$ = new Subject<models.IUserInvitationRequestViewModel>();

    this._dialogService
      .show(InviteBrokerByLandlordDialogComponent, {
        title: null,
        showCloseButton: false,
        closeOnOutsideClick: false,
        width: 700,
        height: 'auto',
        maxWidth: 700,
        injectableData: {
          leaseId: lease.id,
          projectType: models.ProjectTypeEnum.RenewalInitiatedByLandlord,
          tenantCompany: lease.tenantCompany,
          isTenantInvitesBroker: false,
          brokerInvited$: brokerInvited$,
          brokerInvitationType: BrokerInvitationType.RenewalInitiatedByLandlord,
          cancelable: false,
        },
      });

    brokerInvited$
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe(invitationRequest => {
        if (invitationRequest) {
          const brokerName = [invitationRequest.firstName, invitationRequest.lastName]
            .filter(Boolean)
            .join(' ');

          this._alertService.pushSuccessAlert({
            closable: true,
            message: this._alertMessagesManager.getBrokerInvitedAlertText(brokerName),
          });
        }

        this._router.navigate(['/colabo/terms'], {queryParams: {leaseId: lease.id}});
      });
  }

  isCurrentBaseRentalRateSchedulePeriod(row: models.IBaseRentalScheduleRowViewModel): boolean {
    if (!row) {
      return false;
    }

    const today = moment();
    return moment(row.start).isSameOrBefore(today) && moment(row.end).isSameOrAfter(today);
  }

  goToUnits() {
    this.selectedTabIndex = 3;
    this.tabTooltipVisible[this.selectedTabIndex] = !this.tabTooltipVisible[this.selectedTabIndex];
    this.selectTab();
  }

  getLeaseExpirationDate(endDate) {
    const today = new Date();
    const difference = this.getMonthDifference(today, new Date(endDate));
    if (difference < 6 && difference > 0) {
      return 'red-circle';
    } else if (difference >= 6 && difference < 12) {
      return 'orange-circle';
    } else if (difference >= 12 && difference < 24) {
      return 'blue-circle';
    } else if (difference >= 24) {
      return 'green-circle';
    } else {
      return '';
    }
  }

  getMonthDifference(startDate, endDate) {
    return (
      endDate.getMonth() -
      startDate.getMonth() +
      12 * (endDate.getFullYear() - startDate.getFullYear())
    );
  }

  getAvatarUrl(teamMember) {
    return new AvatarListItem(teamMember.fullName,
      teamMember.firstName, teamMember.lastName, teamMember.avatar ? teamMember.avatar.url : null, teamMember.companyName);
  }

  setSuites(suites: Array<models.ISuiteViewModel>) {
    this.suites = suites;
    this.buildingUnits = suites.map(s => s.buildingUnit);
  }

  getDealBaseRentalSchedule(deal: models.IDealViewModel) {
    return deal?.lease?.baseRentalRateTerm?.schedule;
  }
}
