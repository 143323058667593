import { Component, Input, Output, ElementRef, QueryList, Renderer2, EventEmitter } from '@angular/core';
import { AfterViewInit, OnDestroy, ViewChild, ViewChildren } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DxScrollViewComponent, DxTooltipComponent, DxFileUploaderComponent } from 'devextreme-angular';
import { confirm } from 'devextreme/ui/dialog';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { Subject } from 'rxjs';
import {filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { EmojiData } from '@ctrl/ngx-emoji-mart/ngx-emoji/data/data.interfaces';
import * as moment from 'moment';

import { environment } from '../../../../environments/environment';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { MessageManager, Message } from '@statera/sdk/message';

import { AlertService } from '../../../alert/services/alert.service';

import * as models from '../../../infrastructure/models/generated';
import { IFile, MessageType, IChatFileViewModel } from '../../../infrastructure/models/generated';

export enum MessengerContext {
  Chat,
  Comment,
}

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
})
export class MessagesComponent implements AfterViewInit, OnDestroy {
  @Input() height = 0;

  @Input() set messageManager(val) {
    if (val) {
      this._messageManager = val;
      this.cleaning = false;
      this.loadData();
    } else {
      this.messages = [];
      this.cleaning = true;
    }
  }
  get messageManager() {
    return this._messageManager;
  }

  @Input() allowAdd = true;
  @Input() allowEdit = true;
  @Input() allowDelete = true;
  @Input() placeholder = 'Write something here...';
  @Input() disabled = false;
  @Input() confirmSend = false;
  @Input() context: MessengerContext;
  @Input() showEmojiPicker = true;
  @Input() showFileUploader = true;

  @Output() imageUploaded = new EventEmitter<File>();
  @Output() messagesLoaded = new EventEmitter();

  @Output() messageSent: EventEmitter<Message>;

  @ViewChild('scrollView') scrollView: DxScrollViewComponent;
  @ViewChild('scrollView', { read: ElementRef }) scrollViewElementRef: ElementRef;
  @ViewChild('scrollViewContainer') scrollViewContainer: ElementRef;
  @ViewChild('messageMenuToolTip') messageMenuToolTip: DxTooltipComponent;
  @ViewChild('fileUploader') fileUploader: DxFileUploaderComponent;
  @ViewChild('imageUploader') imageUploader: DxFileUploaderComponent;
  @ViewChild('messagesContainer') messagesContainer: any;
  @ViewChild('editorContainer') editorContainer: any;
  @ViewChild('emojiPopover') emojiPopover: PopoverDirective;
  @ViewChild('emojiPopoverTrigger') emojiPopoverTrigger: ElementRef;
  @ViewChild('emojiPopoverEmojiPicker', { read: ElementRef }) emojiPopoverEmojiPicker: ElementRef;

  @ViewChildren('messageElements') set messageElements(messageElements: QueryList<ElementRef>) {
    if (!messageElements || !messageElements.length) {
      return;
    }

    const subscription = messageElements.changes
      .pipe(
        filter((messageElementRefList: QueryList<ElementRef>) => 0 < messageElementRefList.length),
      )
      .subscribe((messageElementRefList: QueryList<ElementRef>) => {
        this._initializeScrollView(messageElementRefList);
        subscription.unsubscribe();
      });
  }

  MessageType = MessageType;
  htmlEditor: any;
  editing = false;
  imageUploadUrl = environment.webApiUrl + '/chat/sendimage';
  filePopupVisible = false;
  uploadedFile: IFile;
  fileUploadUrl = environment.webApiUrl + '/storage/Upload';
  htmlEditorValue = '';
  messages: Array<Message> = [];
  pageSize = 10;
  pageIndex = 0;
  canLoadNext = true;
  tooltipVisible = false;
  _currentEditingMessageId = 0;
  _currentTooltipMessageId = 0;

  ChatMessageKind = models.ChatMessageKind;
  MessengerContext = MessengerContext;

  get currentEditingMessageId() {
    return this._currentEditingMessageId;
  }

  set currentEditingMessageId(val) {
    this._currentEditingMessageId = val;
    if (val) {
      this.currentEditingMessage = this.messages.find(x => x.id === this.currentEditingMessageId);
    } else {
      this.currentEditingMessage = null;
    }
  }

  get currentTooltipMessageId() {
    return this._currentTooltipMessageId;
  }

  set currentTooltipMessageId(val) {
    this._currentTooltipMessageId = val;
    if (val) {
      this.currentTooltipMessage = this.messages.find(x => x.id === this.currentTooltipMessageId);
    } else {
      this.currentTooltipMessage = null;
    }
  }

  currentEditingMessage: Message;
  currentTooltipMessage: Message;
  cleaning = false;
  items = [
    {id: 'attachfile', text: 'Attach File', icon: 'fa fa-file-o'},
  ];

  bottomMenuVisible = false;

  private _documentClickDuringEmojiPopoverOpenListener: () => void;

  private _messageManager: MessageManager<any>;

  private readonly _renderer: Renderer2;
  private readonly _domSanitizer: DomSanitizer;
  private readonly _alertService: AlertService;
  private readonly _alertMessagesManager: AlertMessagesManager;

  private readonly _destroy: Subject<void>;

  constructor(
    domSanitizer: DomSanitizer,
    renderer: Renderer2,
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
  ) {
    this._renderer = renderer;
    this._domSanitizer = domSanitizer;
    this._alertService = alertService;
    this._alertMessagesManager = alertMessagesManager;

    this.messageSent = new EventEmitter<Message>();

    this._destroy = new Subject<void>();
  }

  ngAfterViewInit(): void {
    this.messageMenuToolTip
      ?.onHidden
      ?.pipe(
        tap(() => this.currentTooltipMessageId = null),
        takeUntil(this._destroy),
      )
      ?.subscribe();
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  resetEditor() {
    this.editing = false;
    this.htmlEditorValue = '';
    this.currentEditingMessage = null;
  }

  loadData() {
    this.pageIndex = 0;
    this.messages = [];
    this.getCurrentPage();
  }

  getCurrentPage(done: any = null) {
    const subscription = this._messageManager
      .getMessages(this.pageIndex * this.pageSize, this.pageSize)
      .pipe(
        map(response => response
          .map((item) => {
            item.content = this.removeParagaph(item.content);
            (<Message & {innerHtml: SafeHtml}>item).innerHtml = this._domSanitizer.bypassSecurityTrustHtml(item.content);
            return item;
          }),
        ),
      )
      .subscribe(messages => {
        this.messages = [...messages, ...this.messages]
          .sort((x, y) => x.id - y.id);
        if (this.messagesLoaded) {
          this.messagesLoaded.emit();
        }
      })
      .add(() => {
        if (done) {
          done();
        }

        subscription.unsubscribe();
      });
  }

  getNextPage(done: any = null) {
    if (this.cleaning) {
      this.cleaning = false;
    } else {
      this.pageIndex++;
      this.getCurrentPage(done);
    }
  }

  onMessagesScroll(e) {
    if (this.canLoadNext && e.scrollOffset.top === 0) {
      this.canLoadNext = false;
      this.getNextPage(() => {
        e.component.release();
        this.canLoadNext = true;
      });
    }
  }

  addMessage(message, uploadedFileId: number = null) {
    const newMessage = <Message>{
      content: this.removeParagaph(message),
      uploadedFileId: uploadedFileId,
    };
    if (this.confirmSend) {
      const alertReference = this._alertService.pushConfirmAlert({
        message: this.context === MessengerContext.Comment
          ? this._alertMessagesManager.getConfirmSendCommentAlertText()
          : this._alertMessagesManager.getConfirmSendChatMessageAlertText(),
      });

      const subscription = alertReference
        .confirmed
        .pipe(
          switchMap(() => {
            return this._messageManager.addMessage(newMessage);
          }),
        ).subscribe((msg: Message) => {
          this._processMessage(msg);

          subscription.unsubscribe();
        });
    } else {
      const subscription = this._messageManager
        .addMessage(newMessage)
        .subscribe((msg) => {
          this._processMessage(msg);

          subscription.unsubscribe();
        });
    }
  }

  messageMoreClick(e, messageId: number): void {
    e.stopPropagation();
    this.messageMenuToolTip.instance.option('target', e.target);
    this.tooltipVisible = true;
    this.currentEditingMessageId = messageId;
    this.currentTooltipMessageId = messageId;
  }

  htmlEditorInitialized(e) {
    this.htmlEditor = e.component;

    const Inline = this.htmlEditor.get('blots/inline');
    class EmojiBlot extends Inline {
      static blotName = 'emoji';
      static tagName = 'span';
      static className = 'emoji';

      static create(value: EmojiData) {
        const node = super.create(value.native);

        node.setAttribute('data-id', value.id);
        node.innerText = value.native;

        return node;
      }

      static formats(domNode) {
        if (domNode.getAttribute('data-id')) {
          return {
            'id': domNode.getAttribute('data-id'),
          };
        } else {
          return super.formats(domNode);
        }
      }

      formats() {
        const formats = super.formats();

        formats['emoji'] = EmojiBlot.formats(this.domNode);

        return formats;
      }
    }
    this.htmlEditor.register({ 'formats/emoji': EmojiBlot });
  }

  htmlEditorContentReady() {
    const me = this;
    $('.ql-editor').on('keydown', e => {
      const message = me.htmlEditorValue.trim();
      if (message && !e.shiftKey && e.key === 'Enter') {
        me.sendMessage();
      }
      if (e.key === 'Escape') {
        me.cancelEditing();
      }
    });
  }

  sendMessage() {
    this.htmlEditor.focus();
    const text: string = this.htmlEditorValue.trim();
    this.htmlEditorValue = '';
    this.htmlEditor.focus();

    if (!text) {
      return;
    }

    if (this.editing) {
      const message = JSON.parse(JSON.stringify(this.currentEditingMessage));
      message.content = text;
      this._messageManager
        .updateMessage(message)
        .pipe(
          tap(response => {
            (<Message & {innerHtml: SafeHtml}>this.currentEditingMessage).innerHtml = this._domSanitizer
              .bypassSecurityTrustHtml(response.content);
            this.currentEditingMessage.content = response.content;
            this.currentEditingMessage.edited = true;
            this.currentEditingMessageId = 0;
            this.resetEditor();

            this.messageSent.emit(response);
          }),
          takeUntil(this._destroy),
        )
        .subscribe();
    } else {
      this.addMessage(text);
    }
  }

  cancelEditing() {
    if (
      (this.currentEditingMessage && this.currentEditingMessage.content !== this.htmlEditorValue) ||
      this.htmlEditorValue.trim()
    ) {
      confirm('Cancel editing message?', 'Please confirm').done(confirmed => {
        if (confirmed) {
          this.resetEditor();
        }
      });
    } else {
      this.resetEditor();
    }
  }

  textAreaMenuItemClick(e) {
    e.event.stopPropagation();
    let selection = this.htmlEditor.getSelection();
    if (!selection) {
      this.htmlEditor.focus();
      selection = this.htmlEditor.getSelection();
    }
    const format = this.htmlEditor.getFormat(selection.index, selection.length);
    switch (e.itemData.id) {
      case 'addimage':
        $((<any>this.imageUploader.instance)._selectButton.element()).trigger('click');
        break;
      case 'attachfile':
        this.filePopupVisible = true;
        break;
      case 'bold':
      case 'italic':
      case 'strike':
      case 'underline':
      case 'blockquote':
      case 'code-block':
        this.htmlEditor.format(e.itemData.id, !format[e.itemData.id]);
        break;
      case 'alignleft':
      case 'aligncenter':
      case 'alignright':
      case 'alignjustify':
        const align = e.itemData.id.replace('align', '');
        this.htmlEditor.format('align', format.align && format.align === align ? false : align);
        break;
      case 'orderedlist':
      case 'bulletlist':
        const list = e.itemData.id.replace('list', '');
        this.htmlEditor.format('list', format.list && format.list === list ? false : list);
        break;
    }
  }

  onImageUploaded(e) {
    const file = this.uploadedFile = JSON.parse(e.request.responseText);
    if (this.imageUploaded) {
      this.imageUploaded.emit(file);
    }
    this.addMessage(`<a href="${file.url}"><img src="${file.url}" width="200px" /></a>`, file.id);
    e.component.reset();
  }

  onImageUploadError(e) {
    e.component.reset();
  }

  onImageUploadAborted(e) {
    e.component.reset();
  }

  onFileUploaded(e) {
    this.uploadedFile = JSON.parse(e.request.responseText);
  }

  onFileUploadError(e) {
    e.component.reset();
    this._alertService.pushErrorAlert({
      message: e.request.responseText,
    });
  }

  onFileUploadAborted(e) {
    e.component.reset();
  }

  filePopupAttachClick() {
    const subscription = this._messageManager
      .addMessage(<Message>{
        content: JSON.stringify(<IChatFileViewModel>{fileUid: this.uploadedFile.uid}),
      })
      .subscribe(message => {
        (<Message & {innerHtml: SafeHtml}>message).innerHtml = this._domSanitizer.bypassSecurityTrustHtml(message.content);
        this.messages.push(message);
        this.scrollView.instance.scrollBy(this.scrollView.instance.scrollHeight());
        this.resetEditor();
        subscription.unsubscribe();
      });

    this.fileUploader.instance.reset();
    this.filePopupVisible = false;
  }

  filePopupCancelClick() {
    this.resetFileData();
    this.filePopupVisible = false;
    this.fileUploader.instance.reset();
  }

  resetFileData() {
    this.uploadedFile = null;
  }

  handleEmojiPopoverShown(): void {
    this._documentClickDuringEmojiPopoverOpenListener = this._renderer
      .listen(
        'document',
        'click',
        (event) => this.handleDocumentClickDuringEmojiPopoverOpen(event)
      );
  }

  handleEmojiPopoverHidden(): void {
    this._documentClickDuringEmojiPopoverOpenListener();
  }

  handleDocumentClickDuringEmojiPopoverOpen(event: any): void {
    const targets = [
      this.emojiPopoverTrigger?.nativeElement,
      this.emojiPopoverEmojiPicker?.nativeElement,
      this.htmlEditor.element(),
    ];

    if (targets.some(target => target?.contains(event.target))) {
      return undefined;
    }

    this.emojiPopover.hide();
  }

  handleEmojiPicked(emoji: EmojiData): void {
    this.htmlEditor.getQuillInstance().focus();

    const selection = this.htmlEditor.getSelection();
    const emojiCharSize = emoji.native.length;

    let insertIndex: number;
    if (selection) {
      if (selection?.length) {
        this.htmlEditor.delete(selection.index, selection.length);
      }

      insertIndex = selection.index;
    } else {
      insertIndex = (this.htmlEditor.getLength() || 1) - 1;

      this.htmlEditor.focus();
    }

    this.htmlEditor.insertEmbed(insertIndex, 'emoji', emoji);
    this.htmlEditor.setSelection(insertIndex + emojiCharSize);
  }

  startEditingMessage() {
    const message = this.currentTooltipMessage;

    this.htmlEditor.option('value', message.content);
    this.currentEditingMessageId = message.id;
    this.editing = true;
    this.htmlEditor.focus();
    this.tooltipVisible = false;
  }

  startDeletingMessage() {
    const message = this.currentTooltipMessage;
    const alertReference = this._alertService.pushConfirmAlert({
      message: `Are you sure you want to delete the ${this.context === MessengerContext.Comment ? 'comment' : 'message'}?`
    });
    alertReference.confirmed
      .pipe(
        tap(() => {
          const subscription = this._messageManager
            .deleteMessage(message)
            .subscribe(() => {
              this.messages.splice(this.messages.indexOf(message), 1);
              subscription.unsubscribe();
            });
          this.tooltipVisible = false;
        }),
        take(1),
        takeUntil(this._destroy),
      )
      .subscribe();
  }

  cancelEditingMessage() {
    this.currentEditingMessageId = null;
    this.htmlEditor.option('value', '');
    this.editing = false;
    this.htmlEditor?.getQuillInstance().blur();
  }

  removeParagaph(htmlStr: string) {
    return htmlStr ? htmlStr.replace(/<p><br><\/p>/g, '') : '';
  }

  getDate(date: Date) {
    const today = moment().startOf('day'); // Today
    const yesterDay = moment().subtract(1, 'days').startOf('day');  // Yesterday
    const current = moment(date).startOf('day');  // Current

    if (today.isSame(current)) {
      return 'Today';
    } else if (yesterDay.isSame(current)) {
      return 'Yesterday';
    } else {
      return current.format('MMM DD');
    }
  }

  private _initializeScrollView(messageElementRefList: QueryList<ElementRef>): void {
    if (!this.scrollView || !this.scrollView.instance || !messageElementRefList || !messageElementRefList.length) {
      return;
    }

    const messagesHeight = messageElementRefList.reduce(
      (acc: number, elementRef: ElementRef): number => {
        if (!elementRef.nativeElement) {
          return acc;
        }

        const elementBoundingClientRect = elementRef.nativeElement.getBoundingClientRect();
        return <number>acc + <number>elementBoundingClientRect.height;
      },
      0,
    );

    const scrollViewClientHeight = this.scrollView.instance.clientHeight();
    if (messagesHeight < scrollViewClientHeight) {
      this.getNextPage();
    }

    if (this.messages.length <= this.pageSize) {
      const scrollToBottom = () => {
        const scrollViewScrollHeight = this.scrollView.instance.scrollHeight();
        this.scrollView.instance.scrollBy(scrollViewScrollHeight);
      };

      scrollToBottom();

      const imagePromises: Array<Promise<void>> = [];
      messageElementRefList.forEach((elementRef: ElementRef): void => {
        if (!elementRef.nativeElement) {
          return;
        }

        const imageElements = elementRef.nativeElement.querySelectorAll('img');
        imageElements.forEach((imageElement: HTMLImageElement): void => {
          if (imageElement.complete) {
            imagePromises.push(
              Promise.resolve(),
            );

            return;
          }

          imagePromises.push(
            new Promise((resolve: () => void, reject: () => void): void => {
              imageElement.onload = () => resolve();
              imageElement.onerror = () => reject();
            }),
          );
        });
      });

      if (imagePromises.length) {
        Promise
          .all(imagePromises)
          .then(() => scrollToBottom());
      }
    }
  }

  private _processMessage(msg: Message) {
    (<Message & {innerHtml: SafeHtml}>msg).innerHtml = this._domSanitizer.bypassSecurityTrustHtml(msg.content);
    this.messages.push(msg);
    this.scrollView.instance.scrollBy(this.scrollView.instance.scrollHeight());
    this.resetEditor();
    this.messageSent.emit(msg);
  }
}
